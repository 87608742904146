import React from 'react';
import {Link} from 'gatsby';
import {css} from '@emotion/core';

const ToolBarWithoutDrawer = () => (
	<header
		css={css`
			@media (max-width: 65rem) {
				display: none;
			}
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background: white;
			height: 13vh;
			box-shadow: 0 1vh 0.8vh -0.8vh #c2c2c2;
			z-index: 1;
		`}
	>
		<nav
			css={css`
				display: flex;
				height: 100%;
				align-items: center;
				padding: 0 3.90625vw;
				.logo {
					display: contents;
					a {
						color: #80e8fe;
						text-decoration: none;
						font-size: 2.5vh;
						font-family: Nunito;
						font-weight: 600;
					}
				}
				.navigation-items {
					width: 100vw;
					display: flex;
					li {
						font-size: 2vh;
						font-family: Nunito;
						font-weight: 500;
						display: inline-block;
					}
					a {
						color: #c2c2c2;
						text-decoration: none;
						&:hover,
						&:active {
							color: #80e8fe;
						}
					}
				}
			`}
		>
			<div className="logo">
				<Link to="/">
					<span
						css={css`
							color: black;
						`}
					>
						Auto
					</span>
					Save
				</Link>
			</div>
			<div className="navigation-items">
				<ul
					css={css`
						display: flex;
						align-items: stretch;
						justify-content: space-around;
						width: 50%;
						padding: 0;
						margin-left: 20vh;
						li {
							display: block;
							flex: 0 1 auto;
							list-style-type: none;
						}
					`}
				>
					<li>
						<Link to="/deposits">Explore</Link>
					</li>
					<li>
						<Link to="/faq">FAQ</Link>
					</li>
					<li>
						<Link to="/">Knowledge Base</Link>
					</li>
				</ul>
				{/*
				<ul
					css={css`
						float: right;
						position: absolute;
						right: 3.75vw;
						#loginIndex {
							color: #80e8fe;
							font-size: 2vh;
							margin-right: 5.63vh;
						}
						#signUpIndex {
							width: 8vh;
							height: 50px;
							border-radius: 5vh;
							background-color: #80e8fe;
							color: white;
							outline: none;
							padding: 2vh 5vh;
							font-weight: bold;
							font-size: 2vh;
							box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
						}
					`}
				>
				
					<li>
						<Link id="loginIndex" to="/signin">
							Log in
						</Link>
					</li>
					<li>
						<Link id="signUpIndex" to="/signup">
							Sign up
						</Link>
					</li>
					
				</ul>
				*/}
			</div>
		</nav>
	</header>
);

export default ToolBarWithoutDrawer;
