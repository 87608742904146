import styled from '@emotion/styled';
import {css} from '@emotion/core';

export const Container = styled.div`
	background: #f2f2f2;
	width: 100%;
	position: absolute;
	@media (min-width: 46.25rem) {
		height: -webkit-fill-available;
	}
`;
export const ActionBar = styled.div`
	@media (min-width: 64.99rem) {
		display: none;
	}
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 7rem;
	background: #80e8fe;
	font-family: Nunito;
	color: white;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	z-index: 1;
	box-shadow: 0 0 4px 0 #00000029;
`;
export const mainTextCSS = css`
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 1.6875rem;
	position: fixed;
	top: 3.0625rem;
	left: 4.875rem;
	height: 1.6875rem;
	@media (min-width: 34.375rem) {
		left: 14vw;
	}
`;

export const subTextCSS = css`
	font-size: 0.8125rem;
	font-weight: normal;
	line-height: 1.125rem;
	position: fixed;
	top: 5.1875rem;
	left: 20.8vw;
	width: 15.5rem;
	height: 2.3125rem;
`;
export const BankInfo = styled.div`
	position: absolute;
	left: 0px;
	top: 8.445rem;
	width: 100vw;
	height: 7.5rem;
	background: #fafafa;
	font-family: Nunito;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: rgba(0, 0, 0, 0.54);
	font-weight: normal;
	z-index: -1;
`;
export const bankDescriptionCSS = css`
	font-size: 0.8125rem;
	line-height: 1.125rem;
	margin-left: 27.52vw;
	margin-right: 4vw;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
`;
export const backButtonCSS = css`
	position: fixed;
	left: 4.8vw;
	top: 2.8125rem;
	width: 2.125rem;
	height: 2.125rem;
	object-fit: contain;
`;
export const bankLogoContainer = css`
	position: absolute;
	left: 7.3866vw;
	top: 50%;
	transform: translateY(-50%);
`;
export const bankLogoCSS = css`
	width: 13.335vw;
	height: 5.173vh;
`;
export const formCSS = css`
	display: block;
	margin: auto;
	margin-top: 18rem;
	width: 91.47vw;
	z-index: -1;
`;
