import React from 'react';
import {graphql, Link} from 'gatsby';
import {css} from '@emotion/core';
import {Container, ActionBar, mainTextCSS, backButtonCSS} from '../styles/productListing-styles';

import BackButton from '../assets/product/back-button.svg';
import ToolBarWithoutDrawer from '../components/toolbar-without-drawer';

const ProductListingTemplate = ({data}) => {
	const banks = data.allDeposits.nodes;
	const ProductList = () => {
		return (
			<ul
				css={css`
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					list-style: none;
					flex-direction: column;
					margin: 9rem 0rem 2rem;
					font-family: Nunito;
					@media (min-width: 46.25rem) {
						flex-direction: row;
					}
					@media (min-width: 65rem) {
						margin: 16vh 5vh 4vh;
					}
				`}
			>
				{banks.map((bankinfo) => (
					<Link
						css={css`
							text-decoration: none;
							display: contents;
						`}
						to={`/deposits/${bankinfo.bank.path_name}`}
					>
						<li
							css={css`
								position: relative;
								display: flex;
								background: white;
								width: 80.27vw;
								flex-direction: column;
								margin: 0.8125rem auto;
								padding: 1.5rem 1rem 0;
								border-radius: 0.625rem;
								cursor: pointer;
								@media (min-width: 46.25rem) {
									width: calc(80.27vw / 2);
								}
								@media (min-width: 65rem) {
									width: calc(80.27vw / 3.3);
									margin: 0.8125rem auto 2.625rem;
								}
								&::before {
									content: ' ';
									position: absolute;
									border-radius: 0.625rem;
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.12);
									opacity: 0;
									transition: opacity 0.2s ease 0s;
								}

								&:hover::before {
									opacity: 1;
								}
							`}
						>
							<div
								css={css`
									display: inline-flex;
									flex: 1 1 auto;
									flex-direction: row;
								`}
							>
								<img
									css={css`
										height: 1.5625rem;
									`}
									src={require(`../assets/product/bank-logos-small/${bankinfo.bank.path_name}.svg`)}
									alt={`${bankinfo.bank.path_name} logo`}
								/>
								<div
									css={css`
										display: flex;
										flex: 1 1 auto;
										flex-direction: column;
										padding-left: 1.125rem;
									`}
								>
									<p
										css={css`
											color: black;
											font-weight: bold;
											line-height: 1.25rem;
										`}
									>
										{bankinfo.bank.bank_name}
									</p>
									<p
										css={css`
											margin-top: 0.4125rem;
											font-size: 0.8125rem;
											color: #a2a1a1;
											font-weight: 500;
											line-height: 1.125rem;
										`}
									>
										{bankinfo.bank.bank_description}
									</p>
								</div>
							</div>
							<ul
								css={css`
									list-style: none;
									display: flex;
									flex-direction: row;
									margin: auto;
									font-size: 0.75rem;
									li {
										color: #80e8fe;
										border: 0.5px solid #80e8fe;
										border-radius: 0.3125rem;
										margin: 2.4625vh 1.4vw;
										padding: 0.3rem;
										width: max-content;
										@media (min-width: 46.25rem) {
											margin: 2.2vh 1vw;
										}
										@media (min-width: 65rem) {
											margin: 2.2vh 0.5vw;
										}
									}
								`}
							>
								<li>{bankinfo.bank.maxOfferedInterest} %</li>
								{bankinfo.bank.doOfferMinimumDeposit && <li>Minimum deposit</li>}
								<li>{bankinfo.bank.ratingCRISL}</li>
								<li>
									{bankinfo.bank.rating}{' '}
									<img
										css={css`
											height: 0.5rem;
										`}
										src={require(`../assets/product/star.svg`)}
										alt="Star"
									/>
								</li>
							</ul>
						</li>
					</Link>
				))}
			</ul>
		);
	};
	return (
		<Container>
			<ActionBar>
				<Link to="/">
					<img css={backButtonCSS} src={BackButton} alt="Back" />
				</Link>
				<p css={mainTextCSS}>Explore</p>
			</ActionBar>
			<ToolBarWithoutDrawer />
			<ProductList />
		</Container>
	);
};

export default ProductListingTemplate;

export const pageQuery = graphql`
	query {
		allDeposits {
			nodes {
				bank {
					bank_name
					path_name
					bank_description
					doOfferMinimumDeposit
					maxOfferedInterest
					rating
					ratingCRISL
				}
			}
		}
	}
`;
